@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~leaflet/dist/leaflet.css';

@import 'leaflet/dist/leaflet.css';

/* Fix for Leaflet default marker icon */
.leaflet-default-icon-path {
  background-image: url(https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png);
}

/* Fix for Leaflet control positioning over the slide panel */
.leaflet-control-container .leaflet-top,
.leaflet-control-container .leaflet-bottom {
  z-index: 999;
}

/* Custom scrollbar for the slide panel */
.slide-panel-content::-webkit-scrollbar {
  width: 8px;
}

.slide-panel-content::-webkit-scrollbar-track {
  background: #1f2937;
}

.slide-panel-content::-webkit-scrollbar-thumb {
  background: #374151;
  border-radius: 4px;
}

.slide-panel-content::-webkit-scrollbar-thumb:hover {
  background: #4b5563;
}

/* Ensure map container takes full height */
.leaflet-container {
  height: 100%;
  width: 100%;
  z-index: 1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
